const CONFIG = {
  ALGOLIA_APP_ID: process.env.GATSBY_ALGOLIA_APP_ID,
  ALGOLIA_APP_INDEX: process.env.GATSBY_ALGOLIA_APP_INDEX,
  ALGOLIA_APP_KEY: process.env.GATSBY_ALGOLIA_APP_KEY,
  API_URL: process.env.GATSBY_API_URL || '/api',
  DASHBOARD_URL: process.env.GATSBY_DASHBOARD_URL || '',
  DATADOG_APP_ID: process.env.GATSBY_DATADOG_APP_ID,
  DATADOG_HOST: process.env.GATSBY_DATADOG_HOST || 'datadoghq.eu',
  DATADOG_SERVICE_NAME:
    process.env.GATSBY_DATADOG_SERVICE_NAME || 'smarty-front-end',
  DATADOG_TOKEN: process.env.GATSBY_DATADOG_TOKEN,
  DEBUG: process.env.GATSBY_DEBUG === 'true',
  GROUP_PLANS_VOUCHER_CODE: process.env.GATSBY_GROUP_PLANS_VOUCHER_CODE,
  INTERCOM_APP_ID: process.env.GATSBY_INTERCOM_APP_ID,
  INTERCOM_TOKEN: process.env.GATSBY_INTERCOM_TOKEN,
  MARKETING_URL: process.env.GATSBY_MARKETING_URL || '',
  PAYPAL_DISPLAYNAME: process.env.GATSBY_PAYPAL_DISPLAYNAME,
  BUYAPOWA_ROOT_URL: process.env.GATSBY_BUYAPOWA_ROOT_URL,
  BUYAPOWA_MARKET: process.env.GATSBY_BUYAPOWA_MARKET,
  RAF_VOUCHER_CAMPAIGN_SLUG: process.env.GATSBY_RAF_VOUCHER_CAMPAIGN_SLUG,
  RAF_VOUCHER_VALUE: process.env.GATSBY_RAF_VOUCHER_VALUE,
  RAF_VOUCHER_TYPE: process.env.GATSBY_RAF_VOUCHER_TYPE,
  SMARTY_ENV: process.env.GATSBY_SMARTY_ENV,
  VERSION: process.env.GATSBY_VERSION,
  GOOGLE_PAY_ENV: process.env.GATSBY_GOOGLE_PAY_ENV || 'TEST',
  COMMUNITY_SITE_URL: process.env.GATSBY_COMMUNITY_SITE_URL,
  REFRESH_TOKEN_TIMER_MARGIN: process.env.REFRESH_TOKEN_TIMER_MARGIN || 300000,
  NETWORK_COVERAGE_CHECKER_LINK:
    process.env.GATSBY_NETWORK_COVERAGE_CHECKER_LINK,
  NETWORK_STATUS_CHECKER_LINK: process.env.GATSBY_NETWORK_STATUS_CHECKER_LINK,
  NETWORK_STATUS_CHECKER_OUTDATED_LINK:
    'https://www.three.co.uk/Network_Support_MVNO?mvno=smarty',
  THREE_URL: process.env.GATSBY_THREE_URL,
}

// Env vars are strings - so we need to check that vars are defined and that the string is `true`
const FEATURES = {
  PAYPAL_EXPRESS: process.env.GATSBY_FEATURE_PAYPAL_EXPRESS === 'true',
  APPLE_PAY_PLAN_BANNER_BUTTON:
    process.env.GATSBY_FEATURE_APPLE_PAY_PLAN_BANNER_BUTTON === 'true',
  GOOGLE_PAY_PLAN_BANNER_BUTTON:
    process.env.GATSBY_FEATURE_GOOGLE_PAY_PLAN_BANNER_BUTTON === 'true',
  CHRISTMAS_OPENING_HOURS:
    process.env.GATSBY_FEATURE_CHRISTMAS_OPENING_HOURS === 'true',
  REFER_AND_EARN: process.env.GATSBY_FEATURE_REFER_AND_EARN === 'true',
  REFRESH_TOKEN: process.env.GATSBY_FEATURE_REFRESH_TOKEN === 'true',
  EXIT_INTENT_OVERLAY:
    process.env.GATSBY_FEATURE_EXIT_INTENT_OVERLAY === 'true',
  CMS_STATIC_PAGES: process.env.GATSBY_FEATURE_CMS_STATIC_PAGES === 'true',
  USE_GATSBY_SCRIPT: process.env.GATSBY_FEATURE_USE_GATSBY_SCRIPT === 'true',
  IS_INTERCOM_POSTPONED:
    process.env.GATSBY_FEATURE_IS_INTERCOM_POSTPONED === 'true',
  GENESYS: process.env.GATSBY_FEATURE_GENESYS === 'true',
  HOME_PAGE_BANNER_CAROUSEL:
    process.env.GATSBY_FEATURE_HOME_PAGE_BANNER_CAROUSEL === 'true',
  ROAMING_IRELAND_UPDATES:
    process.env.GATSBY_FEATURE_ROAMING_IRELAND_UPDATES === 'true',
}

module.exports = { CONFIG, FEATURES }
