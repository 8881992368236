import React, { FC, useEffect, useRef } from 'react'

import { useInMediaRange } from '@smarty-nx/code-utils'

import { StyledWrapper } from './styles'
import { TRUSTPILOT_BREAKPOINT } from '../../constants/globalStrings'

interface TrustPilotProps {
  borderBottom?: boolean
  compact?: boolean
  dataTestId?: string
  theme?: string
  reviewTag?: string
}

const TrustPilot: FC<TrustPilotProps> = ({
  borderBottom,
  compact,
  dataTestId = 'trustpilot-widget',
  theme = 'dark',
  reviewTag = 'websitewidget',
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const isMobile = useInMediaRange(0, TRUSTPILOT_BREAKPOINT)

  useEffect(() => {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src =
      'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    if (ref.current) {
      ref.current.appendChild(s)
    }

    return () => {
      if (window.Trustpilot) {
        delete window.Trustpilot
      }
    }
  }, [])

  return (
    <div ref={ref}>
      <StyledWrapper
        compact={compact}
        id="trust-pilot"
        borderBottom={borderBottom}
        data-testid="trustpilot-widget"
      >
        <div
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="59cecd980000ff0005ac6485"
          data-style-height={isMobile ? '300px' : '140px'}
          data-style-width="100%"
          data-theme={theme}
          data-tags={reviewTag}
          data-stars="5"
          data-review-languages="en"
          data-font-family="Montserrat"
          data-testid={dataTestId}
        >
          <a
            href="https://www.trustpilot.com/review/smarty.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
      </StyledWrapper>
    </div>
  )
}

export default TrustPilot
