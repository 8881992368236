import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import cn from 'classnames'

import { content } from './input.content'
import classes from './input.module.scss'
import { InputProps } from './input.types'
import { Icon } from '../icon/icon'

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange,
      onReset,
      defaultValue,
      type = 'text',
      error = false,
      dataTestId,
      className,
      inputDescription,
      alwaysShowReset,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null)
    useImperativeHandle(ref, () => innerRef?.current as HTMLInputElement)
    const value = innerRef?.current?.value
    const [inputValue, setInputValue] = useState(() => '')

    const [isShowingPassword, setIsShowingPassword] = useState(false)

    useEffect(() => {
      setInputValue(value || '')
    }, [value])

    useEffect(() => {
      if (defaultValue) {
        setInputValue(defaultValue)
      }
    }, [defaultValue])

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
      onChange?.(e)
    }

    const resetInput = () => {
      setInputValue('')
      onReset?.()
    }

    return (
      <div className={classes.inputWrapper}>
        {type === 'password' && (
          <button
            type="button"
            className={classes.showPasswordControl}
            onClick={() => setIsShowingPassword(!isShowingPassword)}
            data-testid="show-hide-password-button"
          >
            {isShowingPassword ? content.hidePassword : content.showPassword}
          </button>
        )}
        <input
          className={cn(classes.input, className, {
            [classes.hasError]: error,
          })}
          ref={innerRef}
          id={props.name}
          type={
            type === 'password'
              ? isShowingPassword
                ? 'text'
                : 'password'
              : type
          }
          data-testid={dataTestId || props.name + '-input'}
          {...props}
          defaultValue={defaultValue}
          onChange={handleInputChange}
        />
        {inputDescription}
        {!!inputValue.length && (
          <button
            onClick={resetInput}
            className={cn(
              classes.clearInput,
              alwaysShowReset && classes.clearInputVisible
            )}
            type="button"
            tabIndex={0}
          >
            <Icon name="close-circle" alt="reset" />
          </button>
        )}
      </div>
    )
  }
)
