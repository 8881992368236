import React, { FC, useEffect, useRef, useState } from 'react'

import { priceToString } from '@smarty-nx/code-utils'
import { isUnlimitedPlan } from '@smarty-nx/contentful'
import cn from 'classnames'
import { Link } from 'gatsby'
import { useInViewport } from 'react-in-viewport'

import classes from './plan-card-small.module.scss'
import { CONFIG } from '../../config'
import { TC_LINK } from '../../global.constants'
import PlanCardLoading from '../../static/images/planCardLoading.svg'
import PlanCardLoadingMob from '../../static/images/planCardLoadingMob.svg'
import { triggerModuleLoadEvent } from '../../utils/dataLayer'
import {
  orderDmpgAttributes,
  productDmpgAttributes,
} from '../../utils/dmpgAttributes'
import { convertDataToDisplay } from '../../utils/formatters'
import { PlanData, PlanMeta } from '../../utils/plans.types'
import Button from '../Button'
import OfferRoundel from '../OfferRoundel'
import PlanAdditionalInfo from '../PlanAdditionalInfo'
import PlanCardInner from '../PlanCardInner'
import {
  HideOnMobile,
  HideOnTablet,
  HideSmallDesktopUpwards,
  HideTabletUpwards,
} from '../styles/Responsive'
import { Paragraph } from '../styles/Text'
import TextLink from '../TextLink'

interface PlanCardSmallProps {
  index: number
  to?: string
  href?: string
  position: number
  cta: string
  planMeta: PlanMeta
  priceData: PlanData
  showDetails?: boolean
  isDiscountPlan?: boolean
  isPlanDataOnly?: boolean
  isPlanDetails?: boolean
  hideProductAttributes?: boolean
  hideOrderAttributes?: boolean
  isDataSim?: boolean
  parentElModule: string
}

export const PlanCardSmall: FC<PlanCardSmallProps> = ({
  index,
  to,
  planMeta,
  showDetails,
  position,
  priceData,
  href,
  cta,
  isDiscountPlan,
  isPlanDataOnly,
  isPlanDetails,
  hideProductAttributes,
  hideOrderAttributes,
  isDataSim = false,
  parentElModule,
}) => {
  const myRef = useRef<HTMLDivElement>(null)

  const [orderAttrs, setOrderAttrs] = useState()
  const [productAttrs, setProductAttrs] = useState()
  const { inViewport, enterCount, leaveCount } = useInViewport(
    myRef,
    {},
    { disconnectOnLeave: true },
    {}
  )
  useEffect(() => {
    if (inViewport && enterCount === 1) {
      if (!hideOrderAttributes) {
        const orderServices = orderDmpgAttributes(planMeta, priceData, position)
        setOrderAttrs(orderServices)
      }
      if (!hideProductAttributes) {
        const productServices = productDmpgAttributes(planMeta, priceData)
        setProductAttrs(productServices)
      }
    }
  }, [
    inViewport,
    enterCount,
    leaveCount,
    hideOrderAttributes,
    hideProductAttributes,
    planMeta,
    priceData,
    position,
  ])

  useEffect(() => {
    if (!productAttrs && !orderAttrs) return
    triggerModuleLoadEvent(document, planMeta.slug, parentElModule)
  }, [productAttrs, orderAttrs, parentElModule, planMeta.slug])
  if (!priceData) {
    return (
      <div className={classes.planCardContainer} ref={myRef}>
        <HideTabletUpwards>
          <img
            className={cn(classes.mobileLoadingCard, 'loading')}
            src={PlanCardLoadingMob}
            alt=""
          />
        </HideTabletUpwards>
        <HideOnMobile>
          <img
            className={cn(classes.loadingCard, 'loading')}
            src={PlanCardLoading}
            alt=""
          />
        </HideOnMobile>
      </div>
    )
  }

  const {
    voucher,
    groups,
    originalPrice,
    finalPriceWithoutCredit,
    tcChip,
    style,
  } = priceData
  const defaultPrice = priceToString(priceData.originalPrice, {
    removeZeroPence: true,
  })
  const hasPriceChanged = originalPrice.value !== finalPriceWithoutCredit.value
  const price =
    priceData &&
    priceToString(hasPriceChanged ? finalPriceWithoutCredit : originalPrice, {
      removeZeroPence: true,
    })
  const checkDataAmountIsDiff = (live, local) => {
    if (local.data_number === Infinity) {
      return [false, local.data_number]
    }

    if (!live.voucher) {
      return [false, local.data_number]
    }

    if (typeof live.voucher.data_amount !== 'number') {
      return [false, local.data_number]
    }

    if (local.data_number !== live.voucher.data_amount) {
      return [true, live.voucher.data_amount]
    }

    return [false, live.voucher.data_amount]
  }
  // Only needed until we switch to v3 and stop using group discount vouchers for good
  const isGroupDiscountVoucher =
    voucher && voucher.code === CONFIG.GROUP_PLANS_VOUCHER_CODE
  const isGroupDiscount = !!groups?.groupDiscountPercentage

  const defaultData = planMeta && planMeta.data_number
  const [hasDataChanged, dataAmountSafe] = checkDataAmountIsDiff(
    priceData,
    planMeta
  )
  const data =
    priceData && convertDataToDisplay(planMeta.data_number, dataAmountSafe)
  const isUnlimited = isUnlimitedPlan(planMeta.id)
  const Tag = to ? Link : 'div'

  return (
    <div
      className={classes.planCardContainer}
      ref={myRef}
      id={`${planMeta.slug}-card`}
      data-testid={`${planMeta.slug}-card`}
      {...(orderAttrs || {})}
      {...(productAttrs || {})}
    >
      <PlanCardInner
        background={planMeta.color}
        hasBorder={index !== 3}
        dataTestId={`${planMeta.slug}-card-inner`}
      >
        <Tag
          className={classes.planInfo}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          to={to}
          role="button"
          tabIndex={-1}
          data-testid={`${planMeta.slug}-card-link`}
        >
          {isDiscountPlan && (
            <span className={classes.moneyBack}>Data discount</span>
          )}
          {isPlanDataOnly && (
            <span className={classes.dataOnlySim}>Data only</span>
          )}
          {tcChip && !voucher && (
            <div className={classes.chip}>
              <span>{tcChip}</span>
            </div>
          )}
          {!isPlanDataOnly && (
            <p
              className={classes.sell}
              data-testid={`${planMeta.slug}-card-calls-texts`}
            >
              Unlimited calls &amp; texts
            </p>
          )}

          <div
            className={classes.strikedData}
            data-type="desktop"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            as={hasDataChanged ? null : 'div'}
            aria-label={
              hasDataChanged ? `Normal data amount ${defaultData}GB` : null
            }
          >
            {hasDataChanged && (
              <React.Fragment>
                {defaultData}
                <span>GB</span>
              </React.Fragment>
            )}
          </div>
          <div className={classes.dataWrapper}>
            {isUnlimited ? (
              <h2
                className={cn(
                  classes.planHeading,
                  classes.unlimitedHeading,
                  'plan-data'
                )}
                data-testid={`${planMeta.slug}-card-data`}
              >
                {planMeta.data}
              </h2>
            ) : (
              <h2
                className={cn(classes.planHeading, 'plan-data')}
                data-testid={`${planMeta.slug}-card-data`}
              >
                {data}
                <span>GB</span>
              </h2>
            )}
          </div>

          <div
            className={classes.strikedDataMobile}
            data-type="mobile"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            as={hasDataChanged ? null : 'div'}
            aria-label={
              hasDataChanged ? `Normal data amount ${defaultData}GB` : null
            }
          >
            {hasDataChanged && (
              <React.Fragment>
                {defaultData}
                <span>GB</span>
              </React.Fragment>
            )}
          </div>
          {!isDataSim ? (
            <div className={classes.priceWrapper}>
              <h2
                data-testid={`${planMeta.slug}-card-price`}
                className={classes.planPrice}
              >
                {price}
              </h2>
              {hasPriceChanged && (
                <h3
                  className={classes.strikedPrice}
                  aria-label={`Usually priced at ${defaultPrice}`}
                  data-testid={`${planMeta.slug}-card-striked-price`}
                >
                  &nbsp;{defaultPrice}&nbsp;
                </h3>
              )}
            </div>
          ) : (
            <div
              className={classes.priceWrapper}
              data-testid="data-sim-card-price"
            >
              <h2
                data-testid={`${planMeta.slug}-card-price`}
                className={classes.planPrice}
              >
                {defaultPrice}
              </h2>
            </div>
          )}
        </Tag>
        {voucher && !isGroupDiscountVoucher && (
          <>
            <HideSmallDesktopUpwards>
              <OfferRoundel
                voucher={voucher}
                roundelSide="right"
                roundelPosition="right"
                isPlanCardSmall
                // data discount plans and 4gb-data plan use teal assets
                // big data & 500mb data use yellow assets
                usesYellowAssets={planMeta.usesYellowAssets}
              />
            </HideSmallDesktopUpwards>
            <HideOnTablet>
              <OfferRoundel
                voucher={voucher}
                roundelSide="left"
                roundelPosition="left"
                isPlanCardSmall
                // data discount plans and 4gb-data plans use teal assets
                // big data & 500mb data use yellow assets
                usesYellowAssets={planMeta.usesYellowAssets}
              />
            </HideOnTablet>
          </>
        )}
        {isDiscountPlan && (
          <Paragraph
            className={cn(
              classes.customParagraph,
              classes[
                'backgroundColour' + style?.planColorPale?.replace('/', '')
              ]
            )}
            data-testid={`${planMeta.slug}-card-discount-info`}
          >
            {isPlanDetails ? (
              <a
                href="#expDiscount"
                data-item-category="PP"
                data-item-name="moneyback-more-info"
              >
                {`Up to ${planMeta.data_discount} back every month`}
              </a>
            ) : (
              `Up to ${planMeta.data_discount} back every month`
            )}
          </Paragraph>
        )}
        {((voucher && !isGroupDiscountVoucher) || isGroupDiscount) && (
          <div
            className={
              showDetails
                ? cn(
                    classes[
                      'backgroundColour' +
                        style?.planColorPale?.replace('/', '')
                    ],
                    classes.offerInfo
                  )
                : cn(classes.offerInfo, classes.withPadding)
            }
            data-testid="offer-info"
          >
            <Paragraph
              size="14px"
              lineHeight="20px"
              margin="0 0 8px 0"
              data-testid={`${planMeta.slug}-card-offer-details`}
            >
              {voucher?.plan_card_heading || '10% off as a group member'}
            </Paragraph>
            <Paragraph size="14px" lineHeight="20px">
              <TextLink
                newTab
                href={voucher?.terms_and_conditions_url || TC_LINK}
                ariaLabel="Full offer T&amp;Cs .pdf opens in a new window"
                data-testid={`${planMeta.slug}-card-offer-t&cs`}
              >
                Full offer T&amp;Cs
              </TextLink>
            </Paragraph>
          </div>
        )}
        <div className={classes.additionalInfoWrapper}>
          {showDetails && planMeta.data_discount && (
            <PlanAdditionalInfo
              dataDiscount={planMeta.data_discount as string}
            />
          )}

          <Button
            to={to}
            href={href}
            color={planMeta.color}
            ariaLabel={`${cta} the ${planMeta.name} plan`}
            data-testid={`${planMeta.slug}-card-button`}
            fullWidth={isPlanDataOnly}
            foreColor={undefined}
            onClick={undefined}
            wide={undefined}
            linkStyle={undefined}
            centered={undefined}
            newTab={undefined}
            className={undefined}
            customStyle={undefined}
            eventName={undefined}
            eventProductId={undefined}
            eventCheckoutType={undefined}
            custStyle={undefined}
          >
            {cta} plan
          </Button>
        </div>
      </PlanCardInner>
    </div>
  )
}
