import { TRACKING_STRINGS } from '../../constants/globalStrings'
import { pathFinder } from './pageViews'
import {
  Smdl,
  SmdlAssets,
  SmdlEvent,
  SmdlModule,
  SmdlScreen,
  SmdlScreenEvent,
} from '../../../window'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import findLast from 'lodash/findLast'

// gets the last path item providing there is no trailing slash
const getLast = (path) => {
  const split = path.split('/')
  return split[split.length - 1]
}

// to be used for any baths that use a pattern with many generated outcomes
// e.g roaming pages with all the countries
// key needs to be a regex
// value needs to be a function that accepts the full path which returns the full event
export const dynamicPaths = new Map([
  [
    new RegExp(/^\/roaming\/europe\/[\w]/),
    (path) => ({
      name: `roaming.europe.${getLast(path)}`,
      type: 'support-help',
    }),
  ],
  [
    new RegExp(/^\/roaming\/international\/[\w]/),
    (path) => ({
      name: `roaming.international.${getLast(path)}`,
      type: 'support-help',
    }),
  ],
  [
    new RegExp(/^\/calling-abroad\/countries\/[\w]/),
    (path) => ({
      name: `calling-abroad.countries.${getLast(path)}`,
      type: 'support-help',
    }),
  ],
  // product details pages
  [
    new RegExp(/^\/plans\/[\w-]+/),
    (path) => ({
      name: `plans-${getLast(path)}`,
      type: 'product-details',
    }),
  ],
])

export const buildUser = () => {
  const state = JSON.parse(localStorage.getItem('state') as string)
  if (state && state.auth && state.auth.jwt && state.actingAs !== null) {
    return {
      status: 'logged-in' as const,
      customer: {
        id: state.customerId,
        plan: state.customerPlan,
        actingAs: state.actingAs,
      },
    }
  } else if (state && state.auth && state.auth.jwt) {
    return {
      status: 'logged-in' as const,
      customer: {
        id: state.customerId,
        plan: state.customerPlan,
      },
    }
  }
  return {
    status: 'logged-out' as const,
  }
}

export const setDmpgObject = () => {
  if (!window.smDl) {
    window.smDl = {
      events: [],
    } as any
  }
  window.smDl.user = buildUser()
  window.smDl.screen = getScreenFromLocation()
  window.smDl.platform = {
    version: process.env.GATSBY_VERSION as string,
    commit: process.env.GATSBY_COMMIT as string,
  }
  buildEventsBasedOnScreenType(window.smDl.screen, document)
  attachAllClickEvents(document, window.smDl)
}

const elements = (parent: Document | HTMLElement, selector: string) =>
  Array.from(parent.querySelectorAll<HTMLElement>(selector))

const element = (parent: Document | HTMLElement, selector: string) =>
  parent.querySelector<HTMLElement>(selector)

const toObjectValue = ([name, value]) => {
  const keys = name.split('_')
  return keys.reverse().reduce((acc, key) => ({ [key]: acc }), value)
}

export const convertDataAttributeToObjects = (
  el: HTMLElement
): {
  asset?: Record<string, unknown>
  type?: string
  discount?: unknown
  product?: unknown
  cart?: Record<string, unknown>
  lines?: unknown
  transaction?: { id: string }
  event?: Record<string, unknown>
  module?: Record<string, unknown>
} =>
  Object.entries(el.dataset).reduce(
    (acc, val) => merge(toObjectValue(val), acc),
    {}
  )

const buildItem = (el: HTMLElement) => ({
  id: el.dataset?.itemId,
  type: el.getAttribute('data-type'),
  position: el.getAttribute('data-position'),
})

const findItems = (module: HTMLElement, slug?: string) => {
  return elements(
    module,
    slug ? `[data-item-id="${slug}"]` : '[data-item-id]'
  ).map(buildItem)
}

export const findModules = (
  moduleId: string,
  parentEl: Document | HTMLElement = document,
  slug?: string
) =>
  elements(parentEl, `[data-module-id="${moduleId}"]`).map((el) => ({
    ...convertDataAttributeToObjects(el).module,
    id: el.getAttribute('data-module-id'),
    items: findItems(el, slug),
  })) as SmdlModule[]

const buildItemWithAssetData = (el: HTMLElement) => {
  const { asset, type, discount } = convertDataAttributeToObjects(el)
  return {
    ...asset,
    ...buildDiscount(discount),
    type,
    id: el.getAttribute('data-item-id'),
  }
}

export const findAssets = (
  parentEl: Document | HTMLElement = document,
  slug?: string
) =>
  elements(parentEl, slug ? `[data-item-id="${slug}"]` : '[data-item-id]')
    .map(buildItemWithAssetData)
    .reduce((acc, item) => {
      if (!acc[item.type as string]) {
        acc[item.type as string] = []
      }
      acc[item.type as string].push(omit(item, ['type']))

      return acc
    }, {}) as SmdlAssets

export const findProducts = (parentEl: Document | HTMLElement = document) =>
  elements(parentEl, '[data-product_id]')
    .map(convertDataAttributeToObjects)
    .map(({ product }) => product)

const buildDiscount = (discount) => {
  if (discount) {
    return { discount: { lines: [discount] } }
  }
  return undefined
}

const buildCart = (parentEl) => {
  const cartEl = element(parentEl, '[data-cart_checkout-step]')
  if (cartEl) {
    const { cart } = convertDataAttributeToObjects(cartEl)

    const productLines = elements(parentEl, '[data-lines_id]')
      .map(convertDataAttributeToObjects)
      .map(({ lines, discount }) => ({
        product: lines,
        ...buildDiscount(discount),
      }))

    return { ...cart, lines: productLines }
  }

  return undefined
}

const buildTransaction = (parentEl: Document | HTMLElement) => {
  const transactionEl = element(parentEl, '[data-transaction_id]')
  if (transactionEl) {
    const { transaction } = convertDataAttributeToObjects(transactionEl)
    return transaction
  }

  return undefined
}

export const moduleEvent = (
  container: Document,
  slug: string,
  moduleId: string
): SmdlEvent => ({
  event: TRACKING_STRINGS.MODULE_LOAD,
  modules: findModules(moduleId, container, slug),
  assets: findAssets(container, slug),
  user: buildUser(),
})

const screenEvent = ({ screen, user }): SmdlEvent => ({
  event: TRACKING_STRINGS.SCREEN_LOAD,
  screen,
  user,
})

const compact = (array) => array.filter((value) => value !== undefined)

const buildEventsBasedOnScreenType = (
  { type, name }: SmdlScreen,
  container: Document | HTMLElement
) => {
  let events

  switch (type) {
    case 'product-details':
      return findProducts(container).map((product) => ({
        product,
        event: TRACKING_STRINGS.PRODUCT_VIEW,
        user: buildUser(),
      }))
    case 'checkout':
      const cart = buildCart(container)
      if (name === 'plans-confirmation') {
        const transaction = buildTransaction(container)
        if (transaction && transaction.id) {
          events = [
            {
              event: TRACKING_STRINGS.TRANSACTION_SUCCESS,
              transaction: { ...transaction, cart },
              user: buildUser(),
            },
          ]
        } else {
          events = []
        }
      } else if (name === 'plans-2') {
        events = [
          {
            event: TRACKING_STRINGS.REGISTRATION_SUCCESS,
            user: buildUser(),
          },
        ]
        if (cart) {
          events.push({
            event: TRACKING_STRINGS.CART_LOAD,
            cart: buildCart(container),
            user: buildUser(),
          })
        }
      } else {
        events = []
        if (cart) {
          events.push({
            event: TRACKING_STRINGS.CART_LOAD,
            cart: buildCart(container),
            user: buildUser(),
          })
        }
      }
      return events
    default:
      return []
  }
}

const attachClickEvent = (
  container,
  ele,
  smdlObject,
  clickEvent = 'data-dmpg-event-name'
) =>
  (ele.onclick = () =>
    smdlObject.events.push({
      event: ele.getAttribute(clickEvent),
      ...convertDataAttributeToObjects(ele).event,
    }))

const attachAllClickEvents = (container, smdlObject) => {
  elements(container, '[data-dmpg-event-name]').forEach((el) =>
    attachClickEvent(container, el, smdlObject)
  )
  elements(container, '[data-item-id]').forEach((el) =>
    attachClickEvent(container, el, smdlObject, 'data-item-id')
  )
}

const removeTrailingSlash = (path: string): string => {
  if (path.endsWith('/')) {
    return path.substring(0, path.length - 1)
  }
  return path
}

export const getScreenFromLocation = () => {
  const name = removeTrailingSlash(window.location.pathname)
  let dynamicEvent = {}

  if (typeof pathFinder[name] !== 'undefined') {
    return pathFinder[name]
  }

  dynamicPaths.forEach((func, regex) => {
    if (regex.test(name)) {
      dynamicEvent = func(name)
    }
  })

  return dynamicEvent
}

const dmpgLayerNotAlreadyInitializedForThisScreen = (
  screen: SmdlScreen,
  previouslyAttachedScreen: string | null
) => screen && screen.name !== previouslyAttachedScreen

const completeInitializationForScreen = (
  completedEvents,
  smdlObject,
  container
) => {
  completedEvents.forEach((completedEvent) =>
    smdlObject.events.push(completedEvent)
  )
  attachAllClickEvents(container, smdlObject)
}

const findLastScreenEvent = (smdlObject: Smdl) =>
  findLast(smdlObject.events, (eventData) => {
    return eventData.event === TRACKING_STRINGS.SCREEN_LOAD
  }) as SmdlScreenEvent

const matchScreen = (a: SmdlScreen, b: SmdlScreen) =>
  a.name === b.name && a.type === b.type

const addScreenEventIfItChanged = (smdlObject: Smdl) => {
  const lastScreenEvent = findLastScreenEvent(smdlObject)
  if (
    !lastScreenEvent ||
    !matchScreen(smdlObject.screen, lastScreenEvent.screen)
  ) {
    smdlObject.events.push(screenEvent(smdlObject))
  }
}

const buildDmpgDataAttributeListener = (
  container: Document,
  smdlObject: Smdl,
  getScreen: () => SmdlScreen
) => {
  let previouslyAttachedScreen: string | null = null
  return () => {
    smdlObject.screen = getScreen()
    smdlObject.user = buildUser()
    if (smdlObject.screen) {
      addScreenEventIfItChanged(smdlObject)
    }
    if (
      dmpgLayerNotAlreadyInitializedForThisScreen(
        smdlObject.screen,
        previouslyAttachedScreen
      )
    ) {
      const completedEvents = buildEventsBasedOnScreenType(
        smdlObject.screen,
        container
      )

      if (completedEvents.length > 0) {
        completeInitializationForScreen(completedEvents, smdlObject, container)
        previouslyAttachedScreen = smdlObject.screen.name
      }
    }
  }
}

export const attach = (
  container: Document,
  smdlObject: Smdl,
  getScreen: () => SmdlScreen = getScreenFromLocation
) => {
  const dmpgListener = buildDmpgDataAttributeListener(
    container,
    smdlObject,
    getScreen
  )
  const observer = new MutationObserver(dmpgListener)
  observer.observe(container, { childList: true, subtree: true })
  return observer
}

const buildForm = (el) => {
  const form = el.querySelector('form')
  if (form) {
    return {
      id: form.id,
      name: form.getAttribute('data-form-name'),
    }
  }

  return undefined
}

const eventExtras = (eventName: string, container: HTMLElement | Document) => {
  const form = buildForm(container)
  if (form) return { form }
  if (eventName === 'user.interact.auto.success.loginSuccess') {
    return { user: buildUser() }
  }
  return {}
}

export const addTrackEvent = (
  event: SmdlEvent,
  params: { extras: boolean } = { extras: false }
) => {
  const extras = params.extras ? eventExtras(event.event, document) : {}
  const e = { ...event, ...extras, user: buildUser() }

  window.smDl.events.push(e)
}
