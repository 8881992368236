import React, { FC } from 'react'

import { RaeBanner } from '@smarty-nx/ds'
import { graphql, PageProps } from 'gatsby'

import { RafBanner } from '../components/FriendReferralBanner/RafBanner'
import PageHead from '../components/PageHead'
import withApp, { AppProps } from '../components/withApp/withApp'
import { FEATURES } from '../config'
import { useIsAuthenticated } from '../hooks'
import {
  AllPlansHeading,
  HowItWorksSection,
  PlanBatchesSection,
  TrustPilotSection,
  YouMightBeInterestedSection,
} from '../modules/page-section'

const seoMeta = {
  title: 'All plans',
  description: 'All plans',
}

type AllPlansPageProps = AppProps & PageProps<Queries.AllPlansPageQuery>

const Page: FC<AllPlansPageProps> = ({ data: { headingSection } }) => {
  const isAuthenticated = useIsAuthenticated()

  return (
    <>
      {FEATURES.REFER_AND_EARN && !isAuthenticated && <RaeBanner />}
      {!FEATURES.REFER_AND_EARN && <RafBanner />}
      {headingSection && <AllPlansHeading {...headingSection} />}
      <PlanBatchesSection />
      <TrustPilotSection />
      <HowItWorksSection />
      <YouMightBeInterestedSection />
    </>
  )
}

export default withApp(Page)

export const Head = () => (
  <PageHead title={seoMeta.title} description={seoMeta.description} />
)

export const query = graphql`
  query AllPlansPage {
    headingSection: contentfulGenericBanner(
      type: { eq: "all-plans-header-banner" }
    ) {
      ...GenericBanner
    }
  }
`
