import React, { forwardRef, isValidElement } from 'react'

import cn from 'classnames'

import classes from './text-input.module.scss'
import { TextInputProps } from './text-input.types'
import { Input } from '../input/input'
import { Label } from '../label/label'
import { SupportingText } from '../supporting-text/supporting-text'

/***
 * Design system: https://www.figma.com/file/RE9n7PIwHG7A7WHA7kEVhN/WEB-Components?node-id=3214%3A423
 */

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      labelContent,
      fieldDescription,
      type = 'text',
      error = null,
      onReset,
      errorDataTestId,
      descriptionDataTestId,
      dataTestId,
      wrapperClassName,
      descriptionClassName,
      alwaysShowReset = false,
      ...props
    },
    ref
  ) => (
    <div className={cn(classes.inputWrapper, wrapperClassName)}>
      <Label htmlFor={props.name || ''} text={labelContent}>
        <Input
          type={type}
          id={props.name}
          error={!!error}
          onReset={onReset}
          alwaysShowReset={alwaysShowReset}
          dataTestId={dataTestId}
          {...props}
          ref={ref}
        />
        {error && (
          <SupportingText
            isError
            dataTestId={errorDataTestId || props.name + '-error-tooltip'}
          >
            {error}
          </SupportingText>
        )}
        {fieldDescription && !error && isValidElement(fieldDescription) ? (
          fieldDescription
        ) : (
          <SupportingText
            className={descriptionClassName}
            dataTestId={
              descriptionDataTestId || props.name + '-description-tooltip'
            }
          >
            {fieldDescription}
          </SupportingText>
        )}
      </Label>
    </div>
  )
)
