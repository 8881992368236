import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Plan } from '@smarty-nx/data'

import api from '../../utils/api'
import { RootState } from '../rootReducer'

interface PlansState {
  loading: boolean
  plans: Plan[]
}

const initialState: PlansState = {
  loading: false,
  plans: [],
}

export const fetchGroupPlans = createAsyncThunk<
  Plan[],
  void,
  { state: RootState }
>('group-plans', async (_, { getState }) => {
  const state = getState()

  const isAuthenticated = !!state.dashboard.data?.auth.jwt

  const { data } = await api.get<{
    data: {
      attributes: {
        plans: Plan[]
      }
    }
  }>(`/v3/plans?group_discount=${isAuthenticated}`)

  const plansData = data.data.attributes.plans

  return plansData
})

const groupPlansSlice = createSlice({
  name: 'group-plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGroupPlans.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchGroupPlans.fulfilled, (state, action) => {
      state.loading = false
      state.plans = action.payload
    })

    builder.addCase(fetchGroupPlans.rejected, (state) => {
      state.loading = false
    })
  },
})

export default groupPlansSlice.reducer
